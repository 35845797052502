var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"variant":"white","show":_vm.showLoading,"spinner-variant":"primary","blur":"0","opacity":".75","rounded":"sm"}},[_c('b-card',{attrs:{"no-body":""}},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"p-1"},[_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('h5',[_vm._v("Cabecera")])])],1),_c('b-row',{staticClass:"mb-1"},[_c('b-col',{staticClass:"d-flex flex-column",attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"project_id","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Proyecto","label-for":"project_id"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.proyectos,"label":"description","input-id":"project_id","reduce":function (proyectos) { return proyectos.id; },"placeholder":"Proyecto"},on:{"input":_vm.getSelects},model:{value:(_vm.items.projectId),callback:function ($$v) {_vm.$set(_vm.items, "projectId", $$v)},expression:"items.projectId"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Sede","label-for":"sede"}},[_c('v-select',{staticClass:"select-obra",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.sedes,"label":"description","input-id":"sede","reduce":function (sedes) { return sedes.id; },"placeholder":"Sede"},model:{value:(_vm.items.sedeId),callback:function ($$v) {_vm.$set(_vm.items, "sedeId", $$v)},expression:"items.sedeId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipo de Servicio","label-for":"service"}},[_c('v-select',{staticClass:"select-obra",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.servicios,"input-id":"service","placeholder":"Tipo de Servicio"},model:{value:(_vm.items.type),callback:function ($$v) {_vm.$set(_vm.items, "type", $$v)},expression:"items.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h5',[_vm._v("Detalle")])])],1),_c('b-row',{staticClass:"mb-1"},[_c('b-col',{staticClass:"d-flex flex-column",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"plate","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Codigo de Unidad","label-for":"plate"}},[_c('b-form-input',{attrs:{"id":"plate","placeholder":"Codigo de Unidad","autocomplete":"off"},model:{value:(_vm.items.plate),callback:function ($$v) {_vm.$set(_vm.items, "plate", $$v)},expression:"items.plate"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"dateInit","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Fecha Inicio","label-for":"dateInit"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"dateInit","config":_vm.config},model:{value:(_vm.items.dateInit),callback:function ($$v) {_vm.$set(_vm.items, "dateInit", $$v)},expression:"items.dateInit"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"primaryPilotId","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Piloto 1","label-for":"primaryPilotId"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.getFilteredPilots('secondaryPilotId'),"label":"fullname","input-id":"primaryPilotId","reduce":function (pilot) { return pilot.id; },"placeholder":"Piloto 1"},model:{value:(_vm.items.primaryPilotId),callback:function ($$v) {_vm.$set(_vm.items, "primaryPilotId", $$v)},expression:"items.primaryPilotId"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"stopQty","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Cantidad de Paradas","label-for":"stopQty"}},[_c('b-form-input',{attrs:{"type":"number","id":"stopQty","placeholder":"Cantidad de Paradas","autocomplete":"off"},model:{value:(_vm.items.stopQty),callback:function ($$v) {_vm.$set(_vm.items, "stopQty", $$v)},expression:"items.stopQty"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{staticClass:"d-flex flex-column",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"route","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Ruta","label-for":"route"}},[_c('b-form-input',{attrs:{"id":"route","placeholder":"Ruta","autocomplete":"off"},model:{value:(_vm.items.route),callback:function ($$v) {_vm.$set(_vm.items, "route", $$v)},expression:"items.route"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"dateEnd","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Fecha Fin","label-for":"dateEnd"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"dateEnd","config":_vm.config},model:{value:(_vm.items.dateEnd),callback:function ($$v) {_vm.$set(_vm.items, "dateEnd", $$v)},expression:"items.dateEnd"}}),_c('small',{staticClass:"text-danger alert",style:({ height: (errors.length > 0 ? 20 : 0) + 'px' })},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"secondaryPilotId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Piloto 2","label-for":"secondaryPilotId"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.getFilteredPilots('primaryPilotId'),"label":"fullname","input-id":"secondaryPilotId","reduce":function (pilot) { return pilot.id; },"placeholder":"Piloto 2"},model:{value:(_vm.items.secondaryPilotId),callback:function ($$v) {_vm.$set(_vm.items, "secondaryPilotId", $$v)},expression:"items.secondaryPilotId"}})],1)]}}],null,true)})],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }